const KEY_PREFIX = 'PEOPLE_DASH';

const getStorageKey = (key: string): string => {
  return `${KEY_PREFIX}_${key.replace(/-/g, '_').toUpperCase()}`;
};

export const createStorage = (storage: Storage) => ({
  setItem(key: string, value: any): void {
    try {
      const storageKey = getStorageKey(key);
      const data = JSON.stringify(value);
      storage.setItem(storageKey, data);
    } catch (error) {
      console.error('Error setting data in storage:', error);
    }
  },
  getItem(key: string): any {
    try {
      const storageKey = getStorageKey(key);
      const data = storage.getItem(storageKey);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error getting data from storage:', error);
      return null;
    }
  },
  removeItem(key: string): void {
    try {
      const storageKey = getStorageKey(key);
      storage.removeItem(storageKey);
    } catch (error) {
      console.error('Error removing data from storage:', error);
    }
  },
  clear(): void {
    try {
      storage.clear();
    } catch (error) {
      console.error('Error clearing storage:', error);
    }
  }
});

const useLocalStorage = (): boolean => JSON.parse(sessionStorage.getItem('useLocalStorage') || 'false');

export const SessionStorage = createStorage(useLocalStorage() ? localStorage : sessionStorage);
export const LocalStorage = createStorage(localStorage);

export const clearStorage = (): void => {
  LocalStorage.clear();
  SessionStorage.clear();
};

export const setTokenStorage = (token = ''): void => {
  LocalStorage.setItem('token', token);
};

export const setCsrfToken = (csrfToken = ''): void => {
  LocalStorage.setItem('csrf_token', csrfToken);
};

export const setCurrentUser = ({ user_name = '', user_email = '', user_id = '' }): void => {
  LocalStorage.setItem('user', { user_name, user_email, user_id });
};

export const setCurrentAccount = (account = ''): void => {
  SessionStorage.setItem('current_account', account);
};

export const setCurrentOrganization = ({ organization = '', organizationName = '', originAppName = '' }): void => {
  SessionStorage.setItem('organization', { organization, organizationName, originAppName });
};

export const setContactCandidate = (contactCandidate = {}): void => {
  SessionStorage.setItem('contact_candidate', contactCandidate);
};

export const setCandidateInfos = (candidateInfos = {}): void => {
  SessionStorage.setItem('candidate_infos', candidateInfos);
};

export const getTokenStorage = (): string | null => {
  return LocalStorage.getItem('token');;
};

export const getCsrfToken = (): string | null => {
  return LocalStorage.getItem('csrf_token');
};

export const getCurrentUser = (): {
  user_name: string | null;
  user_email: string | null;
  user_id: string | null;
} => {
  const user = LocalStorage.getItem('user') || {};

  return {
    user_name: user?.user_name || '',
    user_email: user?.user_email || '',
    user_id: user?.user_id || ''
  };
};

export const getCurrentAccount = (): string | null => {
  return SessionStorage.getItem('current_account');;
};

export const getCurrentOrganization = (): {
  organization: string | null;
  organizationName: string | null;
  originAppName: string | null;
} => {
  const org = SessionStorage.getItem('organization') || {};

  return {
    organization: org?.organization || '',
    organizationName: org?.organizationName || '',
    originAppName: org?.originAppName || ''
  };
};

export const getContactCandidate = (): any => {
  return SessionStorage.getItem('contact_candidate');;
};

export const getCandidateInfos = (): any => {
  return SessionStorage.getItem('candidate_infos');;
};

export const removeContactDetailsStorage = (): void => {
  LocalStorage.removeItem('contact_candidate');
  SessionStorage.removeItem('contact_candidate');

  LocalStorage.removeItem('candidate_infos');
  SessionStorage.removeItem('candidate_infos');

  LocalStorage.removeItem('contactsList');
  SessionStorage.removeItem('contactsList');
};
