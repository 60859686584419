import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/modules/admission/_store';
import { setCurrentAccount } from '../storage/storage.service';

declare let environment: any;

@Injectable({ providedIn: 'root' })
export class OrganizationsService {
    private url: string = environment.url;

    constructor(private http: HttpClient, private store: Store<any>) {}

    /**
     * Get organization and account infos
     * @param access_token token
     */
    getOrganization(access_token): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${access_token}`
            }),
            withCredentials: true
        };
        return this.http.get<any>(`${this.url}/organization`, httpOptions);
    }

    setOrganization(account) {
        const acc = account;
        let list = [];
        this.store.pipe(select(fromStore.getOrganizationState)).subscribe(res => {
            list = res?.groups;
          });

        if (list.length > 0) {
            const companies = list.reduce((all, item) => {
                item.accounts.map(res => {
                    all.push(res);
                });
                return all;
            }, []);
            const currentAccount = companies.filter(x => x.uid === acc)[0];
            this.store.dispatch(new fromStore.ChangeAccout(currentAccount));
            setCurrentAccount(acc);
            return currentAccount;
        }
      }
}
