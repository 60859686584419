import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { CallbackService, CodeChallenge } from '@app/callback/callback.service';
import { LaunchdarklyService } from '@acesso-io/launchdarkly-client-ts/lib/launchdarkly';
import { error } from 'console';
import { clearStorage, getTokenStorage } from '@app/core/_shared/services/storage/storage.service';

declare let environment: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private identity_url: string = environment.identity_url;
  private base_url: string = environment.base_url;
  private redirect_uri: string = environment.redirect_uri;
  private url: string = environment.url;

  constructor(public jwtHelper: JwtHelperService,
    private http: HttpClient,
    private callbackService: CallbackService,
    private launchdarklyService: LaunchdarklyService
  ) {}

  loggedIn(): boolean {
    const token: string = getTokenStorage()
    return token != null && !this.jwtHelper.isTokenExpired(token);
  }

  logoutApi(): Observable<any> {
    return this.http.get<any>(`${this.url}/logout`);
  }

  logout(): void {
    this.logoutApi().subscribe(() => {
      clearStorage();
      localStorage.setItem('displayModal', 'false');
      window.location.href = `${this.identity_url}/signout?redirect_uri=${this.base_url}`;
    })
  }

  async startPKCEAuthenticationFlow(): Promise<string> {
    let match = this.launchdarklyService?.evaluateBoolean({flagKey: "people_authorization_code_with_pkce"});
    let identityFinalURL = `${this.identity_url}/oauth2/authorize?scope=*&redirect_uri=${this.redirect_uri}&response_type=code&client_id=8aead3e6-b295-4f77-b314-86b975ee3ae2`

    if (match) {
      const codeChallenge = await this.callbackService.getCodeChallenge().toPromise().catch((err) => {
        console.error(err)
        return {} as CodeChallenge
      })
      if (codeChallenge.raw && codeChallenge.method) {
        let pkceQueryParams = `&code_challenge=${codeChallenge.raw}&code_challenge_method=${codeChallenge.method}`
        identityFinalURL = `${identityFinalURL}${pkceQueryParams}`
      }
    }
    return identityFinalURL
  }
}
