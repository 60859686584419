import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, switchMap, distinctUntilChanged, tap, map } from 'rxjs/operators';

import { SearchService } from '@app/core/_shared/services/search/search.service';
import { Observable, of } from 'rxjs';
import { filter, catchError } from 'rxjs/operators';
import { getCurrentAccount, getCurrentOrganization } from '@app/core/_shared/services/storage/storage.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  results$: Observable<any>;
  formSearch: FormGroup;
  isFocused = false;
  currentQueryParams: any = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService
  ) {}

  @HostListener('document:click', ['$event'])
  clickedOutside() {
    this.isFocused = false;
  }

  ngOnInit() {
    this.currentQueryParams = {
      oid: getCurrentOrganization().organization,
      oname: getCurrentOrganization().organizationName,
      aname: getCurrentOrganization().originAppName,
      acc: getCurrentAccount()
    };
    this.createForm();
  }

  clickInside(e: Event): void {
    e.stopPropagation();
  }

  createForm(): void {
    this.formSearch = this.fb.group({
      search: ['']
    });
    this.initSearch();
  }

  initSearch(): void {
    this.results$ = this.formSearch.get('search').valueChanges.pipe(
      filter(term => term),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.searchService.preview(term)),
      catchError(err => {
        return of();
      })
    );
  }

  initSearchWithValue(term: string): void {
    if (!this.results$) {
      this.results$ = this.searchService.preview(term);
    }
  }

  onBlur(element: HTMLInputElement): void {
    if (!element.value) {
      this.isFocused = false;
      this.results$ = null;
    }
    element.setAttribute('placeholder', 'Buscar por nome ou CPF');
  }

  onFocus(element: HTMLInputElement): void {
    this.isFocused = true;
    element.setAttribute('placeholder', '');
    if (!element.value) {
      this.initSearch();
      return;
    }
    this.initSearchWithValue(element.value);
  }

  submitSearch(element: HTMLInputElement): void {
    this.router.navigate(['/search'], {
      queryParams: {
        q: element.value
      },
      relativeTo: this.route
    });

    this.clearSearch(element);
  }

  clearSearch(element?: HTMLInputElement): void {
    this.isFocused = false;
    this.results$ = null;

    if (element && element.value) {
      element.value = '';
    }
  }
}
