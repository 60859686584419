<header class="dashboard-header" id="main-header">
  <div class="side-left">
    <button
      class="button-open-menu"
      data-testid="sidebar-button"
      aria-label="Abre menu lateral"
      id="open-sidebar-account"
      type="button"
      (click)="openMenu()"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
        <rect width="18" height="2" fill="#B0B0B0" />
        <rect y="6" width="18" height="2" fill="#B0B0B0" />
        <rect y="12" width="18" height="2" fill="#B0B0B0" />
      </svg>
    </button>

    <div class="block-logo" data-testid="logo-box">
      <a [routerLink]="baseUrl"><img src="./assets/images/core/logo-unico-people.svg" alt="Logomarca unico | people" /></a>
      <span class="account-name" data-testid="company-name">{{currentAccount?.name}}</span>
    </div>
  </div>

  <div class="side-right">
    <app-search></app-search>

    <a href="https://empresas.unico.io" aria-label="Central de Ajuda" class="helper-link" rel="noopener" target="_blank">
      <img src="./assets/images/core/ico-help.svg" alt="Ícone da Central de Ajuda" />
    </a>

    <div class="block-user-infos" data-testid="user-name">
      <button mat-button [matMenuTriggerFor]="menu">
        <div class="button-user-infos">
          <img width="32" data-testid="user-logo" src="./assets/images/core/user.svg" alt="Ícone de avatar do usuário" />
          <span class="name" data-testid="user-name"> {{user.user_name}}</span>
          <img width="10" data-testid="user-logo" src="./assets/images/core/ico-open-settings.svg" alt="Ícone abrir opções" />
        </div>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item class="menu-button" data-testid="preferences-button" (click)="preferences()">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#161616">
            <path
              d="M400-492.31q-57.75 0-98.87-41.12Q260-574.56 260-632.31q0-57.75 41.13-98.87 41.12-41.13 98.87-41.13 57.75 0 98.87 41.13Q540-690.06 540-632.31q0 57.75-41.13 98.88-41.12 41.12-98.87 41.12ZM100-187.69v-88.93q0-30.3 15.46-54.88 15.46-24.58 43.16-38.04 49.84-24.84 107.69-41.5 57.84-16.65 133.69-16.65h11.69q4.85 0 10.46 1.23-6.07 14.15-10.03 28.84-3.97 14.7-6.58 29.93H400q-69.08 0-122.31 15.88-53.23 15.89-91.54 35.81-13.61 7.31-19.88 17.08t-6.27 22.3v28.93h252q4.46 15.23 11.58 30.92 7.11 15.69 15.65 29.08H100Zm544.23 29.61-8.92-53.08q-14.31-4.23-26.93-11.07-12.61-6.85-24-17.16l-50.69 17.62-28.46-48.39L546.62-304q-4.31-15.54-4.31-30.62 0-15.07 4.31-30.61l-41-34.62 28.46-48.38 50.3 18q11-10.31 23.81-16.96 12.81-6.66 27.12-10.89l8.92-53.07h56.92l8.54 53.07q14.31 4.23 27.12 11.2 12.8 6.96 23.8 17.88l50.31-19.23 28.46 49.61-41 34.62q4.31 14.43 4.31 30.06 0 15.63-4.31 29.94l41.39 33.84-28.46 48.39-50.7-17.62q-11.38 10.31-24 17.16-12.61 6.84-26.92 11.07l-8.54 53.08h-56.92Zm28.11-100.38q31.43 0 53.77-22.38 22.35-22.38 22.35-53.81 0-31.43-22.38-53.77-22.38-22.35-53.81-22.35-31.42 0-53.77 22.38t-22.35 53.81q0 31.42 22.38 53.77t53.81 22.35ZM400-552.31q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0-80Zm12 384.62Z"
            />
          </svg>

          Minhas Preferências
        </button>
        <button mat-menu-item class="menu-button logout" data-testid="logout-button" (click)="logout()">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#eb0042">
            <path
              d="M212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h268.07v60H212.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v535.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h268.07v60H212.31Zm436.92-169.23-41.54-43.39L705.08-450H363.85v-60h341.23l-97.39-97.38 41.54-43.39L820-480 649.23-309.23Z"
            />
          </svg>

          Sair
        </button>
      </mat-menu>
    </div>
  </div>
</header>
