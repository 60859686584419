import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";
import { Extra, ScopeContext } from '@sentry/types';
import { PeopleUser } from '../../models/people-user';
import { HttpErrorResponse } from '@angular/common/http';
import { getCurrentOrganization, getCurrentUser } from '../storage/storage.service';

declare const environment;

@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {

    handleError(error: any): void {
      console.error(error)

      const chunkFailedMessage = /Loading chunk [\d]+ failed/;
      if (chunkFailedMessage.test(error.message)) {
        window.location.reload();
      }

      const context = this.makeContext();
      if (!environment.production) {
        return
      }

      if (error instanceof HttpErrorResponse) {
        if(this.hasSendHttpErrorEvent(error)) {
          context.extra["error"] = {raw: error?.error, status: error?.status}
          Sentry.captureMessage(error.message, context)
        }
      } else {
        Sentry.captureException(error, context)
      }
    }

    private hasSendHttpErrorEvent(error: HttpErrorResponse): boolean {
      let send = true
        for (const code in environment?.sentry_ignore_status_code_errors?.split(",")) {
          if (error?.status?.toString() === code) {
            send = false
            break
          }
      }

      return send
    }

    private getPeopleUser(): PeopleUser | undefined {
      const data: PeopleUser = getCurrentUser();
      if(data) {
        try {
          return data
        } catch (error) {
          // pass
        }
      }
      return undefined
    }

    private makeContext() {
      const user = this.getPeopleUser();
      const context = {
        level: 'error',
        extra: {
          "rhUser": {
            id: user?.user_id,
            name: user?.user_name,
            email: user?.user_email,
          },
        } as Extra,
      } as ScopeContext;

      if (user) {
        context.user = {} as Sentry.User;
        context.user.id = getCurrentOrganization().organization
        context.user.username = getCurrentOrganization().organizationName
      }
      return context;
    }
}
