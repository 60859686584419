<div class="search-container" [ngClass]="{'is-focused': isFocused }" (click)="clickInside($event)">
  <form class="search-form" [formGroup]="formSearch" (ngSubmit)="submitSearch(field)">
    <input
      class="search-input"
      data-testid="search-input"
      type="text"
      formControlName="search"
      #field
      (focus)="onFocus($event.target)"
      (blur)="onBlur($event.target)"
      aria-label="Buscar por nome ou CPF"
      placeholder="Buscar por nome ou CPF"
    />
    <button class="search-button" type="submit" tabindex="-1">
      <img src="./assets/images/dashboard/ico-magnifier.svg" alt="Buscar" />
    </button>
  </form>

  <div class="search-autocomplete">
    <ng-container *ngIf="field.value.length; else notStarted">
      <ng-container *ngIf="results$ | async as search; else loading">
        <ng-container *ngIf="search.result.length; else empty">
          <ul>
            <li *ngFor="let result of search.result | slice :0:5;" data-testid="search-result-item">
              <a
                [routerLink]="result?.index === 'admissions' ? '/candidate' : '/collaborators/profile'"
                class="search-link"
                [queryParams]="result?.index === 'admissions' ? {
                  p: result?.source?.id,
                  u: result?.source?.unit,
                  oid: currentQueryParams.oid,
                  oname: currentQueryParams.oname,
                  aname: currentQueryParams.aname,
                  acc: currentQueryParams.acc
                } : {
                  p: result.id,
                  u: result.source.unit,
                  oid: currentQueryParams.oid,
                  oname: currentQueryParams.oname,
                  aname: currentQueryParams.aname,
                  acc: currentQueryParams.acc
                }"
                (click)="clearSearch(field)"
              >
                <span class="result-item"> {{ result?.source?.name ? result?.source?.name : result?.source?.inviteName }}</span>
                <span class="result-status"> {{ result?.index === 'collaborators' ? 'Colaboradores' : 'Admissão'}} </span>
              </a>
            </li>
          </ul>

          <button
            *ngIf="search.result.length > 5"
            data-testid="button-see-more"
            (click)="submitSearch(field)"
            type="button"
            class="search-button"
          >
            Ver mais
          </button>
        </ng-container>

        <ng-template #empty>
          <p class="empty-result">Nenhum resultado encontrado.</p>
        </ng-template>
      </ng-container>

      <ng-template #loading>
        <p class="empty-result">Pesquisando...</p>
      </ng-template>
    </ng-container>

    <ng-template #notStarted>
      <p class="empty-result">Comece a digitar para exibir os resultados.</p>
    </ng-template>
  </div>
</div>
