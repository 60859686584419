import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

import { Environment } from '@app/core/_shared/interfaces/environment/environment.interface';
import { SessionStorage } from '@app/core/_shared/services/storage/storage.service';

declare const environment: Environment;
declare global {
  interface Window {
    Cypress: any;
    msw: any;
    hj: any
  }
}

@Injectable({
  providedIn: 'root',
})

export class AuthGuardService implements CanActivate {
  constructor(private auth: AuthService) {}

  async canActivate() {
    if (window.msw) return true;

    if (this.auth.loggedIn()) {
      return true;
    } else {
      const locationUrl = window.location.pathname;
      const routeSendEnvelope = '/shipping-documents'
      if(locationUrl.includes(routeSendEnvelope)) {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        if (params.get('product_id') === 'sign') {
          SessionStorage.setItem('redirect_url', routeSendEnvelope);
        }
      }

      let url = await this.auth.startPKCEAuthenticationFlow()
      window.location.href = url

      return false;
    }
  }
}
